import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    Input, PaginationLink, PaginationItem, Pagination, CardFooter,
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine";
import { connect } from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import SelectRow from "../../components/Select/SelectRow";
import {AvForm} from "availity-reactstrap-validation";
import strings from "../../core/translate";
import FormRow from "../../components/Row/FormRow";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import {Utilites} from "../../core/Utilites";

class Userprofileadvanced extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            iscustomer: false,
            ismember: false,
            page: 0,
            count: 0,
            pageSize: 20,
            percentfees:"40",
            profitdate:"",
            profit:"",
            editedProfits: {} // To store edited profits for each user by ID
        };
        this.pathname = "user";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage();
    }
    handleProfitChange = (userId, value) => {
        this.setState((prevState) => ({
            editedProfits: {
                ...prevState.editedProfits,
                [userId]: {
                    ...prevState.editedProfits[userId], // Preserve existing fields
                    profit: value // Update only profit
                }
            }
        }));
    };

    handleSave = async () => {
        const { editedProfits, profitdate } = this.state;

        this.props.loadingAction(true);

        const userdata = Object.entries(editedProfits).map(([userId, changes]) => ({
            userId,
            updatedProfit: changes.profit || null, // Include profit if updated
            percentfees: changes.percent_fees || null, // Include percent fees if updated
            profitdate
        }));

        try {
            debugger
            const response = await this.engine.saveItem("batchuserprofit", userdata);
            if (response && response.status === 200) {
                Utilites.showSucessMessage("Save successful");
                this.callPage(); // Refresh data
            } else {
                Utilites.showErrorMessage("Save failed");
            }
        } catch (error) {
            Utilites.showErrorMessage("An error occurred");
        } finally {
            this.props.loadingAction(false);
        }
    };

    async callPage(page = 0, pageSize = 20, filtered = []) {
        const { iscustomer, ismember } = this.state;
        const response = await this.engine.postItem("user", `/list/${page}/${pageSize}?iscustomer=${iscustomer}&ismember=${ismember}`, filtered);

        if (response && response.status === 200) {
            this.setState({
                isLoading: false,
                count: response.data.data.count,
                page: page,
                pageSize: pageSize,
                dataSource: response.data.data.data.map((item, key) => ({
                    id: key,
                    data: item,
                    email: item.email,
                    _id: item._id,
                    first_name: item.first_name+" "+item.last_name,
                    username: item.username,
                    percent_fees:item.ismember?"yes":"no",
                    ismember: item.ismember?"Yes":"No",
                    profit: item.profit || "", // Add profit field
                    created: this.renderDate(item.createdAt),
                }))
            });
        }
        this.props.loadingAction(false);
    }

    handlePercentFeesChange = (userId, value) => {
        this.setState((prevState) => ({
            editedProfits: {
                ...prevState.editedProfits,
                [userId]: {
                    ...prevState.editedProfits[userId], // Preserve existing fields
                    percent_fees: value // Update only percent fees
                }
            }
        }));
    };
    render() {
        const { dataSource, isLoading, count, page, pageSize, editedProfits,percentfees,profitdate } = this.state;

        const columns = [
            {
                Header: "Email",
                accessor: "email"
            },

            {
                Header: "full name",
                accessor: "first_name"
            },
            {
                Header: "Username",
                accessor: "username"
            },
            {
                Header: "is member",
                accessor: "ismember"
            },
            {
                Header: "Created",
                accessor: "created"
            },

            {
                Header: "Percent_fees",
                accessor: "percent_fees",
                Cell: ({ original }) => (
                    original.percent_fees.includes("no") ? (
                        <Input
                            type="select"
                            value={editedProfits[original._id]?.percent_fees ?? original.percent_fees ?? ""}
                            onChange={(e) => this.handlePercentFeesChange(original._id, e.target.value)}
                            style={{ width: "100%" }}
                        >
                            <option value="40">40</option>
                            <option value="45">45</option>
                            <option value="50">50</option>
                        </Input>
                    ) : (
                        <div>{original.percent_fees}</div>
                    )
                )
            },
            {
                Header: "Profit",
                accessor: "profit",
                Cell: ({ original }) => (
                    <Input
                        type="text"
                        value={editedProfits[original._id]?.profit ?? original.profit ?? ""}
                        onChange={(e) => this.handleProfitChange(original._id, e.target.value)}
                        onFocus={(e) => e.target.select()}
                    />
                )
            },
        ];

        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <AvForm onValidSubmit={() => this.handleSave()} className="form-horizontal" id="TypeValidation">
                                    <Card>

                                        <CardBody>
                                            <FormRow label={"fees date"} type={"date"} name="profitdate" data={profitdate} changeInput={this.changeInput.bind(this)} />
                                        </CardBody>
                                    </Card>
                                </AvForm>

                                <Button color="primary" onClick={this.handleSave} className="pull-right">
                                    Save
                                </Button>
                            </CardHeader>
                            <CardBody>
                                <Pagination className="pagination pagination-primary" style={{ overflow: "scroll" }}>
                                    {Array.from({ length: count }).map((_, index) => (
                                        <PaginationItem key={index}>
                                            <PaginationLink onClick={() => this.callPage(index, pageSize)}>
                                                {index + 1}
                                            </PaginationLink>
                                        </PaginationItem>
                                    ))}
                                </Pagination>
                                <ReactTable
                                    data={dataSource}
                                    filterable
                                    manual
                                    loading={isLoading}
                                    columns={columns}
                                    defaultPageSize={20}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    onFetchData={(state) => {
                                        this.setState({ isLoading: true });
                                        this.callPage(state.page, state.pageSize, state.filtered);
                                    }}
                                    pages={count}
                                    defaultPage={page}
                                    className="-striped -highlight primary-pagination"
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(Userprofileadvanced);
