import React from "react";
import { withRouter } from "react-router-dom";
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine";
import { connect } from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import moment from "moment";

class UserMonthlyReport extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            userinfo: {},
            userhistory: [],
            myuserprofit: [], // Add userprofit state
            refferallistlevel1: [],
            refferallistlevel2: [],
            refferallistlevel3: [],
            refferallistlevel4: [],
            refferallistlevel5: [],
            refferallistlevel6: [],
            restuserprofit:[]
        };
        this.pathname = "user";
        this.engine = new RequestEngine();
    }

    async componentDidMount() {
        const id = this.props.match.params.id;
        const response = await this.engine.getItem(this.pathname, "/view/" + id);

        if (response && response.status === 200) {
            const {
                userinfo,
                userhistory,
                myuserprofit,  // Add userprofit from response
                refferallistlevel1,
                refferallistlevel2,
                refferallistlevel3,
                refferallistlevel4,
                refferallistlevel5,
                refferallistlevel6,
                restuserprofit,
            } = response.data.data;

            this.setState({
                userinfo,
                userhistory,
                restuserprofit,
                myuserprofit,  // Set userprofit in state
                refferallistlevel1,
                refferallistlevel2,
                refferallistlevel3,
                refferallistlevel4,
                refferallistlevel5,
                refferallistlevel6,
                isLoading: false,
            });
        }
    }

    // Helper function to calculate profit based on rank and referral level
    calculateProfit(level, rank, isMember, isCustomer) {
        if (!rank) return 0;

        let profitPercent = 0;
        if (isMember) {
            profitPercent = rank[`level${level}profitmember`];
        } else if (isCustomer) {
            profitPercent = rank[`level${level}profitcustomer`];
        }

        return profitPercent ? profitPercent : 0;
    }

    // Calculate Commissions on Membership
    calculateMembershipCommission(level, usedcredit, rank) {
        if(!rank){
            return 0;
        }
        const membershipCommissionPercent = rank[`level${level}membership`] || 0;
        return (usedcredit * membershipCommissionPercent)/100;
    }

    // Render Referral List for Each Level
    renderReferralList = (level, list) => {
        const { userinfo,restuserprofit } = this.state;

        const currentuserismember = userinfo.ismember;
        const isCustomer = userinfo.iscustomer;
        const rank = userinfo.rank;

        let totalRevenue1 = 0;
        let totalRevenue2 = 0;
        let totalRevenue3 = 0;
        let totalRevenue4 = 0;
        let totalRevenue5 = 0;

        return (
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Referral Level {level}</CardTitle>
                </CardHeader>
                <CardBody>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Username</th>
                            <th>Used Credit</th>
                            <th>Is Member</th>
                            <th>Bonus.Membership</th>
                            <th>Com.Members</th>
                            <th>Com.Customer (40%)</th>
                            <th>Com.Customer (45%)</th>
                            <th>Com. Customer (50%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {list && list.length > 0 ? list.map((item, index) => {
                            const isMember = item.ismember;
                            const profitPercent = this.calculateProfit(level, rank, currentuserismember, isCustomer);
                            const usedcredit = item.credit * 2;
                            const selectedUserProfit = restuserprofit.find(x=>x.user==item._id)?.profit || 0;
                            const percentfees = restuserprofit.find(x=>x.user==item._id)?.percentfees || 0;
                            const membershipCommission = this.calculateMembershipCommission(level, usedcredit, rank);
                            const revenue =((parseFloat(selectedUserProfit)*0.3)/(percentfees/100)*profitPercent)/100;
                            const commembers =isMember?(parseFloat(selectedUserProfit)*(profitPercent/100)):0;


                            totalRevenue1+=membershipCommission;
                            totalRevenue2+=commembers;
                            if(!isMember && percentfees == 40){
                                totalRevenue3+=revenue;
                            }
                            if(!isMember && percentfees == 45){
                                totalRevenue4+=revenue;
                            }
                            if(!isMember && percentfees == 50){
                                totalRevenue5+=revenue;
                            }

                            return (
                                <tr key={index}>
                                    <td>{item.username}</td>
                                    <td>{usedcredit}</td>
                                    <td>{isMember ? "YES" : "NO"}</td>
                                    <td> {membershipCommission.toFixed(2)}   </td>
                                    <td> {commembers.toFixed(2)+" "+profitPercent+" "+selectedUserProfit}   </td>
                                    <td>{!isMember && percentfees == 40 ? revenue.toFixed(2) : 0} </td>
                                    <td>{!isMember && percentfees == 45 ? revenue.toFixed(2) : 0} </td>
                                    <td>{!isMember && percentfees == 50 ? revenue.toFixed(2) : 0} </td>

                                </tr>
                            );
                        }) : <tr>
                            <td colSpan="10">No referrals found</td>
                        </tr>}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td>Revenue</td>
                            <td></td>
                            <td></td>
                            <td>{totalRevenue1}</td>
                            <td>{totalRevenue2}</td>
                            <td>{totalRevenue3}</td>
                            <td>{totalRevenue4}</td>
                            <td>{totalRevenue5}</td>
                        </tr>

                        <hr/>
                        <tr>
                            <td>Total Revenue</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><strong>{(totalRevenue1+totalRevenue2+totalRevenue3+totalRevenue4+totalRevenue5).toFixed(2)}</strong></td>
                        </tr>
                        </tfoot>
                    </Table>
                </CardBody>
            </Card>
        );
    };

    renderUserProfit = () => {
        const {myuserprofit} = this.state;

        return (
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">User Fees</CardTitle>
                </CardHeader>
                <CardBody>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Fees Type</th>
                            <th>Fees</th>
                            <th>Close Trade</th>
                            <th>Profit Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {myuserprofit && myuserprofit.length > 0 ? myuserprofit.map((item, index) => (
                            <tr key={index}>
                                <td>My User Profit</td>
                                <td>{item.profit}</td>
                                <td>{item.closetrade}</td>
                                <td>{moment(item.profitdate).format('YYYY-MM-DD')}</td>
                            </tr>
                        )) : <tr><td colSpan="4">No user profits found</td></tr>}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        );
    };

    render() {
        const { isLoading, userinfo, userhistory, refferallistlevel1, refferallistlevel2, refferallistlevel3, refferallistlevel4,refferallistlevel5,
            refferallistlevel6 } = this.state;

        if (isLoading) {
            return this.renderProgress();
        }

        return (
            <>
                <div className="content english">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">User Information</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Table responsive>
                                        <tbody>
                                        <tr><td>Username</td><td>{userinfo.username}</td></tr>
                                        <tr><td>First Name</td><td>{userinfo.first_name}</td></tr>
                                        <tr><td>Last Name</td><td>{userinfo.last_name}</td></tr>
                                        <tr><td>Email</td><td>{userinfo.email}</td></tr>
                                        <tr><td>Country</td><td>{userinfo.country}</td></tr>
                                        <tr><td>Status</td><td>{userinfo.status}</td></tr>
                                        <tr><td>Balance</td><td>{userinfo.balance}</td></tr>
                                        <tr><td>Credit</td><td>{userinfo.credit}</td></tr>
                                        <tr><td>Referral Code</td><td>{userinfo.myreferral}</td></tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Transaction History */}
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Transaction History</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Table responsive>
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Transaction Type</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>Created At</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {userhistory && userhistory.length > 0 ? userhistory.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.name}</td>
                                                <td>{item.transactiontype}</td>
                                                <td>{item.amount}</td>
                                                <td>{item.status}</td>
                                                <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                                            </tr>
                                        )) : <tr><td colSpan="5">No transactions found</td></tr>}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Referral Lists */}
                    <Row>
                        <Col md="12">
                            {this.renderReferralList(1, refferallistlevel1)}
                            {this.renderReferralList(2, refferallistlevel2)}
                            {this.renderReferralList(3, refferallistlevel3)}
                            {this.renderReferralList(4, refferallistlevel4)}
                            {this.renderReferralList(5, refferallistlevel5)}
                            {this.renderReferralList(6, refferallistlevel6)}
                        </Col>
                    </Row>

                    {/* User Profit Table */}
                    <Row>
                        <Col md="12">
                            {this.renderUserProfit()}
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});

export default connect(null, mapDispatchToProps)(withRouter(UserMonthlyReport));
