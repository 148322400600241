import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import { Utilites } from "../../core/Utilites";
import Constants from "../../core/Constants";
import Checkboxrow from "../../components/Checkbox/Checkboxrow";


export default class ClientAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            _id: "",
            title: "",
            level:"",
            levellist:[],
            ispremium:false,
            description: "",
            categorylist: [],
            category: "",
            status:"active",
            lang:"english",
            statuslist: [
             { value: "active", label: "active" },
             { value: "inactive", label: "inactive" },

         ],
            loading: false,
        };
        this.pathname = "course";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            this.setState({
                ...edit,
                category: edit.category?.id,
                startDate: edit.startDate ? new Date(edit.startDate).toISOString().substr(0, 10) : "",
                level: edit.level?.id
            });
        }
        this.prepareData();
    }
    async prepareData() {
        let responseuni = await this.engine.getItemlistDirect("category");
        if (responseuni) {
            const data = responseuni.data.data;
            let categorylist = [];
            data.map((p) => {
                categorylist.push({ value: p._id, label: p.title });
            });
            this.setState({
                categorylist,
            });
        }

        responseuni = await this.engine.getItemlistDirect("level");
        if (responseuni) {
            const data = responseuni.data.data;
            let levellist = [];
            data.map((p) => {
                levellist.push({ value: p._id, label: p.title });
            });
            this.setState({
                levellist,
                isLoading:false
            });
        }


    };


    handleValidSubmit = async () => {
        this.setState({
            loading: true
        })

        const { _id, title, description, endDate,ispremium, startDate, level,lang,category } = this.state;



        const data = {
            picture: this.idFileBase64, // Using the base64 string
            title: title,
            description: description,
            endDate: endDate,
            startDate: startDate,
            level,
            category: category,
            _id,lang,ispremium
        };

        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            this.props.history.push('/admin/' + this.pathname);
        }
        this.setState({
            loading: false
        })
    };

    onChangePic(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64 = result;
        });
    }

    render() {
        // taking all the states
        const { loading, isLoading, title,ispremium, description,levellist,level, lang, status,statuslist, category, categorylist } = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <Checkboxrow label="is premium" name="ispremium" data={ispremium}  changeInput={this.changeInput.bind(this)} />

                                        <SelectRow
                                            label={"category"}
                                            name="category"
                                            defaultValue={category}
                                            data={categorylist}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        <SelectRow
                                            label={"level"}
                                            name="level"
                                            defaultValue={level}
                                            data={levellist}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        <FormRow label={"title"} name="title" data={title} changeInput={this.changeInput.bind(this)} />
                                        <FormRow type={"textarea"} label={"description"} name="description" data={description} changeInput={this.changeInput.bind(this)} />

                                           <SelectRow
                                            label={"status"}
                                            name="status"
                                            defaultValue={status}
                                            data={statuslist}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        <SelectRow
                                            label={"Language"}
                                            name="lang"
                                            defaultValue={lang}
                                            data={this.createSelectValue(Constants.langlist)}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        <Row>
                                            <Label sm="2">{strings.picture}  </Label>
                                            <Col sm="5">
                                                <FormGroup >
                                                    <ImageUpload  placeholder={this.state.fullpicture} labelupload="Select Image" onChange={(e) => this.onChangePic(e)}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


