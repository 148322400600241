import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import { Utilites } from "../../core/Utilites";
import Constants from "../../core/Constants";


export default class GptAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",

            description: "",
            loading: false,
        };
        this.pathname = "gpt";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            debugger
            this.setState({
                ...edit,

            });
        }
        this.prepareData();
    }
    async prepareData() {
        let responseuni = await this.engine.getItemlistDirect("gpt");
        if (responseuni) {
            const data = responseuni.data.data;
            this.setState({
                description:data.description,
            });
        }

    };


    handleValidSubmit = async () => {
        this.setState({
            loading: true
        })

        const { _id, title, description } = this.state;



        const data = {

            title: title,
            description: description,
            _id
        };

        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
          alert("Saved successfully!");
        }
        this.setState({
            loading: false
        })
    };

    onChangePic(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64 = result;
        });
    }

    render() {
        // taking all the states
        const { loading, isLoading, title, description,levellist,level, lang, status,statuslist, category, categorylist } = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>


                                        <FormRow type={"textarea"} label={"Learn"} name="description" data={description} changeInput={this.changeInput.bind(this)} />


                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


