import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import DynamicTbl from "./DynamicTbl";


export default class ClientAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",
            title: "",
            score:"0",
            levelwin:"",
            levelfailed:"",
            levellist:[],
            categorylist: [],
            category: "",
            loading: false,
            attributelistall:[],
            attributelist:[],
            variationlist:[],
        };
        this.pathname = "question";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            debugger
            this.setState({
                ...edit,
                variationlist:edit.variationlist,
                category: edit.category?.id,
                levelfailed: edit.levelfailed?.id,
                levelwin: edit.levelwin?.id,
            });
        }
        this.prepareData();
    }
    async prepareData() {
        let responseuni = await this.engine.getItemlistDirect("category");
        if (responseuni) {
            const data = responseuni.data.data;
            let categorylist = [];
            data.map((p) => {
                categorylist.push({ value: p._id, label: p.title });
            });
            this.setState({
                categorylist,
            });
        }

        responseuni = await this.engine.getItemlistDirect("level");
        if (responseuni) {
            const data = responseuni.data.data;
            let levellist = [];
            data.map((p) => {
                levellist.push({ value: p._id, label: p.title });
            });
            this.setState({
                levellist,
            });
        }

    };

    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })

        let data = { ...this.state }

        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            this.props.history.push('/admin/' + this.pathname);
        }
        this.setState({
            loading: false
        })

    };

    fillAttribute(data){
            this.setState({
                variationlist:data
            })
    }

    render() {
        // taking all the states
        const { loading, isLoading,levellist,levelwin,levelfailed, title,score, variationlist, category, categorylist } = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">
                    <h4></h4>
                    <Row>
                        <Col md="12">
                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <SelectRow
                                            label={"question category"}
                                            name="category"
                                            defaultValue={category}
                                            data={categorylist}
                                            changeInput={this.changeInput.bind(this)}
                                        />

                                        <FormRow label={"title"} name="title" data={title} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"success score"} name="score" data={score} changeInput={this.changeInput.bind(this)} />

                                        <SelectRow
                                            label={"level success"}
                                            name="levelwin"
                                            defaultValue={levelwin}
                                            data={levellist}
                                            changeInput={this.changeInput.bind(this)}
                                        />

                                        <SelectRow
                                            label={"level failed"}
                                            name="levelfailed"
                                            defaultValue={levelfailed}
                                            data={levellist}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        <DynamicTbl fillAttribute={(e)=>{this.fillAttribute(e)}}  colx={["question", "answers","correct_answer"]}  initialdata={variationlist}/>

                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


