import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";


export default class ClientAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",
           status:"",
            code:"",
            amount:"",
           statuslist: [
            { value: "completed", label: "Completed" },
            { value: "pending", label: "Pending" },
            { value: "canceled", label: "Canceled" }
        ],
            loading: false,
        };
        this.pathname = "transaction";
        this.engine = new RequestEngine();
    }



    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            this.setState({
                ...edit,
                user: edit.user && edit.user.id,
            });
        }

    }

    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })

        let data  = {...this.state}

        const response = await this.engine.saveItem(this.pathname,data)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const { loading, isLoading,code,  amount, transactiontype,status,statuslist } = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>




                                        <FormRow label={"receipt"} required={false} name="code" data={code} changeInput={this.changeInput.bind(this)}  readonly={true} />
                                        <FormRow label={"amount"} name="amount" data={amount} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"transactiontype"} name="transactiontype" data={transactiontype} changeInput={this.changeInput.bind(this)} readonly={true} />
                                        <SelectRow
                                            label={"status"}
                                            name="status"
                                            defaultValue={status}
                                            data={statuslist}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


