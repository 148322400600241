import LocalizedStrings from 'react-localization';
import en from "./en"
import ar from "./ar"
import kr from "./kr"
let strings = new LocalizedStrings({
    en:en,
    ar:ar,
    kr:kr
});

export default strings
