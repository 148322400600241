import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import moment from "moment";

export default class NotificationAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",
            message: "",
            profit:"0",
            profitdate:"0",
            closetrade:"0",
            percentfees:"",
            user: "",
            userlist: [],
            loading: false,

        };
        this.pathname = "userprofit";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            const profitdate = edit.profitdate ?moment(edit.profitdate).format('YYYY-MM-DD'):"";
            this.setState({
                ...edit,
                user:edit.user?.id,
                profitdate,
                percentfees:edit.percentfees?.toString()
            });
        }else{
            const profitdate = moment().format('YYYY-MM-DD');
            this.setState({
                profitdate
            });
        }
        this.prepareData();

    }
    async prepareData() {
        let responseuni = await this.engine.getItemlistDirect("user");
        if (responseuni) {
            const data = responseuni.data.data;
            let userlist = [];
            data.map((p) => {
                userlist.push({ value: p._id, label: p.username+" / "+p.first_name +" "+p.last_name });
            });
            this.setState({
                userlist,
            });
        }
    };



    handleValidSubmit = async () => {
        this.setState({
            loading: true
        })

        const { _id, message,user,profitdate,profit,closetrade,percentfees } = this.state;



        const data = {
            user,
            profit,
            message,
            profitdate,
            percentfees,
            closetrade,
            _id
        };

        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            this.props.history.push('/admin/' + this.pathname);
        }
        this.setState({
            loading: false
        })
    };



    render() {
        // taking all the states
        const { loading, isLoading, profit,profitdate,user,userlist,percentfees } = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">
                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <SelectRow
                                            label={"user"}
                                            name="user"
                                            defaultValue={user}
                                            data={userlist}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        <SelectRow
                                            label={"percent fees"}
                                            name="percentfees"
                                            defaultValue={percentfees}
                                            data={this.createSelectValue(["","40","45","50"])}
                                            changeInput={this.changeInput.bind(this)}
                                        />

                                        <FormRow label={"fees date"} type={"date"} name="profitdate" data={profitdate} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"fees"} type={"number"} name="profit" data={profit} changeInput={this.changeInput.bind(this)} />

                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


