import React, { Component } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import Chart from 'react-apexcharts';
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine";
import { connect } from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import strings from "../../core/translate";

class ReportList extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            barChartSeries: [{
                name: 'Net Profit',
                data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
            }, {
                name: 'Revenue',
                data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
            }, {
                name: 'Free Cash Flow',
                data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
            }],
            barChartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                },
                yaxis: {
                    title: {
                        text: '$ (thousands)'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "$ " + val + " thousands"
                        }
                    }
                }
            },
            pieChartSeries: [44, 55, 41],
            pieChartOptions: {
                chart: {
                    type: 'pie',
                    height: 350
                },
                labels: ['Team A', 'Team B', 'Team C'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            salesChartSeries1: [{
                name: 'Sales',
                data: [30, 40, 45, 50, 49, 60, 70, 91]
            }],
            salesChartSeries2: [{
                name: 'Sales',
                data: [20, 30, 35, 40, 39, 50, 60, 81]
            }],
            salesChartSeries3: [{
                name: 'Sales',
                data: [10, 20, 25, 30, 29, 40, 50, 71]
            }],
            salesChartOptions: {
                chart: {
                    type: 'line',
                    height: 300,
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: 'Sales Trends',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                }
            },
            gaugeChartOptions1: {
                chart: {
                    type: 'radialBar',
                    height: 150
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,
                        hollow: {
                            margin: 0,
                            size: '70%',
                            background: '#fff',
                            image: undefined,
                            imageOffsetX: 0,
                            imageOffsetY: 0,
                            position: 'front',
                            dropShadow: {
                                enabled: true,
                                top: 3,
                                left: 0,
                                blur: 4,
                                opacity: 0.24
                            }
                        },
                        track: {
                            background: '#fff',
                            strokeWidth: '67%',
                            margin: 0, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: -3,
                                left: 0,
                                blur: 4,
                                opacity: 0.35
                            }
                        },

                        dataLabels: {
                            showOn: 'always',
                            name: {
                                offsetY: -10,
                                show: true,
                                color: '#888',
                                fontSize: '17px'
                            },
                            value: {
                                formatter: function (val) {
                                    return val;
                                },
                                color: '#111',
                                fontSize: '36px',
                                show: true,
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: ['#ABE5A1'],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100]
                    }
                },
                series: [70],  // Example values
                labels: ['Number 1'],
            },
            gaugeChartOptions2: {
                chart: {
                    type: 'radialBar',
                    height: 150
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,
                        hollow: {
                            margin: 0,
                            size: '70%',
                            background: '#fff',
                            image: undefined,
                            imageOffsetX: 0,
                            imageOffsetY: 0,
                            position: 'front',
                            dropShadow: {
                                enabled: true,
                                top: 3,
                                left: 0,
                                blur: 4,
                                opacity: 0.24
                            }
                        },
                        track: {
                            background: '#fff',
                            strokeWidth: '67%',
                            margin: 0, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: -3,
                                left: 0,
                                blur: 4,
                                opacity: 0.35
                            }
                        },

                        dataLabels: {
                            showOn: 'always',
                            name: {
                                offsetY: -10,
                                show: true,
                                color: '#888',
                                fontSize: '17px'
                            },
                            value: {
                                formatter: function (val) {
                                    return val;
                                },
                                color: '#111',
                                fontSize: '36px',
                                show: true,
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: ['#ABE5A1'],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100]
                    }
                },
                series: [80],  // Example values
                labels: ['Number 2'],
            },
            gaugeChartOptions3: {
                chart: {
                    type: 'radialBar',
                    height: 150
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,
                        hollow: {
                            margin: 0,
                            size: '70%',
                            background: '#fff',
                            image: undefined,
                            imageOffsetX: 0,
                            imageOffsetY: 0,
                            position: 'front',
                            dropShadow: {
                                enabled: true,
                                top: 3,
                                left: 0,
                                blur: 4,
                                opacity: 0.24
                            }
                        },
                        track: {
                            background: '#fff',
                            strokeWidth: '67%',
                            margin: 0, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: -3,
                                left: 0,
                                blur: 4,
                                opacity: 0.35
                            }
                        },

                        dataLabels: {
                            showOn: 'always',
                            name: {
                                offsetY: -10,
                                show: true,
                                color: '#888',
                                fontSize: '17px'
                            },
                            value: {
                                formatter: function (val) {
                                    return val;
                                },
                                color: '#111',
                                fontSize: '36px',
                                show: true,
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: ['#ABE5A1'],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100]
                    }
                },
                series: [90],  // Example values
                labels: ['Number 3'],
            }
        };
        this.pathname = "content";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage();
    }

    async callPage() {
        const response = await this.engine.getItem(this.pathname);

        if (response && response.status === 200) {
            this.setState({
                isLoading: false,
                dataSource: response.data.data.map((item, key) => {
                    return {
                        id: key,
                        data: item,
                        title: item.title,
                        description: item.description,
                        created: this.renderDate(item.createdAt)
                    };
                })
            });
        }
        this.props.loadingAction(false);
    }

    handleAdd() {
        this.props.history.push('/admin/' + this.pathname + '/add');
    }

    render() {
        const { isLoading, barChartSeries, barChartOptions, pieChartSeries, pieChartOptions, salesChartSeries1, salesChartSeries2, salesChartSeries3, salesChartOptions, gaugeChartOptions1, gaugeChartOptions2, gaugeChartOptions3 } = this.state;

        return (
            <div className="content">
                <Row>
                    <Col md="4">
                        <Card>
                            <CardBody>
                                <Chart options={gaugeChartOptions1} series={[70]} type="radialBar" height={250} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card>
                            <CardBody>
                                <Chart options={gaugeChartOptions2} series={[80]} type="radialBar" height={250} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card>
                            <CardBody>
                                <Chart options={gaugeChartOptions3} series={[90]} type="radialBar" height={250} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <Card>
                            <CardBody>
                                <Chart options={salesChartOptions} series={salesChartSeries1} type="line" height={300} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card>
                            <CardBody>
                                <Chart options={salesChartOptions} series={salesChartSeries2} type="line" height={300} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card>
                            <CardBody>
                                <Chart options={salesChartOptions} series={salesChartSeries3} type="line" height={300} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <Card>
                            <CardBody>
                                <Chart options={barChartOptions} series={barChartSeries} type="bar" height={350} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <CardBody>
                                <Chart options={pieChartOptions} series={pieChartSeries} type="pie" height={350} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    loadingAction: payload => dispatch(loadingAction(payload))
});

export default connect(null, mapDispatchToProps)(ReportList);
