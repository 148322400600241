import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import Constants from "../../core/Constants";
import {Utilites} from "../../core/Utilites";


export default class ClientAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",
            title: "",
            link: "",
            orderindex:"0",
            thumbnail: "",
            lang:"english",
            length: "",
            categorylist: [],
            category: "",
            loading: false,
            picture: "",
        };
        this.pathname = "video";
        this.engine = new RequestEngine();
    }
    onChangePic(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64 = result;
        });
    }
    onChangePic2(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase642 = result;
        });
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            this.setState({
                ...edit,
                category: edit.category && edit.category.id,
            });
        }
        this.prepareData();
    }
    async prepareData() {
        let responseuni = await this.engine.getItemlistDirect("category");
        if (responseuni) {
            const data = responseuni.data.data;
            let categorylist = [];
            data.map((p) => {
                categorylist.push({ value: p._id, label: p.title });
            });
            this.setState({
                categorylist,
            });
        }
    };
    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })

        let data = { ...this.state }

        data.thumbnail=this.idFileBase64
        data.video=this.idFileBase642
        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            this.props.history.push('/admin/' + this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const { loading, isLoading, title,lang, link, length, orderindex, categorylist, category } = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <SelectRow
                                            label={"category"}
                                            name="category"
                                            defaultValue={category}
                                            data={categorylist}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        <SelectRow
                                            label={"Language"}
                                            name="lang"
                                            defaultValue={lang}
                                            data={this.createSelectValue(Constants.langlist)}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        <FormRow label={"title"} name="title" data={title} changeInput={this.changeInput.bind(this)} />
                                       <FormRow label={"order index"} name="orderindex" data={orderindex} type={"number"} changeInput={this.changeInput.bind(this)} />


                                        <Row>
                                            <Label sm="2">Video </Label>
                                            <Col sm="5">
                                                <FormGroup >
                                                    <ImageUpload isfile={true}  placeholder={this.state.fullvideo} labelupload="Select Video" onChange={(e) => this.onChangePic2(e)}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


