import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import Constants from "../../core/Constants";
import strings from "../../core/translate";

class BannerList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
        };
        this.pathname = "banner";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
    }



    async handleDelete(valueid) {
        if(window.confirm("Are you sure you want to delete ? ")){
            this.props.loadingAction(true)
            const result = await this.engine.deleteItem(this.pathname,valueid)
            if(result){
                this.handlePage();
            }
            this.props.loadingAction(false)
        }
    }

    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data

        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/edit/'+dataid,
            data
        });

    }

    handleActions(index,item) {

        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                {strings.actions}
            </DropdownToggle>
            <DropdownMenu>

                <DropdownItem  eventKey="2" onClick={() => this.handleEdit(index,item._id)}>{strings.edit}</DropdownItem>
                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>{strings.delete}</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>)
    }






    async callPage() {

        const response = await  this.engine.getItem(this.pathname);


        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                    dataSource: response.data.data.map((item, key) => {
                        return {
                            id: key,
                            data:item,
                            title: item.title ,
                            link:item.link,
                            picture:item.fullpicture && item.fullpicture.length>1 ? <img key={key}  src={item.fullpicture} className={"imgsponser"} />:"-" ,
                            created: this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key,item)
                            )
                        };
                    })
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }


    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add');
    }


    handleGlobalFilterChange = event => {
        const { name, value } = event.target;
        if (name === 'globalFilter') {
            this.setState({ globalFilter: value });
        } else if (name === 'startDateFilter' || name === 'endDateFilter') {
            this.setState({ [name]: value });
        }
    };
    render() {
        const { dataSource, isLoading, globalFilter, startDateFilter, endDateFilter } = this.state;

        let filteredData = [...dataSource];

        if (globalFilter) {
            filteredData = filteredData.filter(row =>
                Object.values(row.data).some(
                    value =>
                        value &&
                        String(value)
                            .toLowerCase()
                            .includes(globalFilter.toLowerCase())
                )
            );
        }


        if (startDateFilter && endDateFilter) {
            const startDate = new Date(startDateFilter);
            const endDate = new Date(endDateFilter);
            endDate.setDate(endDate.getDate() + 1);

            filteredData = filteredData.filter(row =>
                new Date(row.created) >= startDate &&
                new Date(row.created) < endDate
            );
        }


        let columns = [

            {
                Header: "title",
                accessor: "title"
            },

            {
                Header: "picture",
                accessor: "picture"
            },

            {
                Header: strings.created,
                accessor: "created",
            },
            {
                Header: strings.actions,
                accessor: "actions",
                sortable: false,
                filterable: false
            }
        ]


        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">
                                        {" "}
                                    </CardTitle>
                                    <h3 className="pull-left">Banner</h3>
                                    <Button color="primary" className="pull-right" onClick={() =>
                                        this.handleAdd()
                                    }>
                                        {strings.add}
                                    </Button>
                                </CardHeader>

                                <CardBody>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        loading={isLoading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight primary-pagination"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(BannerList);
