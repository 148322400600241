import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import SelectRow from "../../components/Select/SelectRow";
import {Utilites} from "../../core/Utilites";
import ImageUpload from "../../components/CustomUpload/ImageUpload";


export default class RankAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",
            title: "",
            leg1:"",
            leg2:"",
            legc:"",
            orderx:"1",
            dc:"0",
            leg1rank:"",
            leg2rank:"",
            legcrank:"",
            customerpercent:"",
            memberpercent:"",
            duration:"0",
            droppercent:"0",
            credit:"0", // total
            description:"",
            rank:"",
            ranklist:[],

            level1membership: "0",
            level1profitcustomer: "0",
            level1profitmember: "0",
            level1commissions: "0",

            level2membership: "0",
            level2profitcustomer: "0",
            level2profitmember: "0",
            level2commissions: "0",

            level3membership: "0",
            level3profitcustomer: "0",
            level3profitmember: "0",
            level3commissions: "0",

            level4membership: "0",
            level4profitcustomer: "0",
            level4profitmember: "0",
            level4commissions: "0",

            level5membership: "0",
            level5profitcustomer: "0",
            level5profitmember: "0",
            level5commissions: "0",

            level6membership: "0",
            level6profitcustomer: "0",
            level6profitmember: "0",
            level6commissions: "0",

            level7membership: "0",
            level7profitcustomer: "0",
            level7profitmember: "0",
            level7commissions: "0",

            level8membership: "0",
            level8profitcustomer: "0",
            level8profitmember: "0",
            level8commissions: "0",

            level9membership: "0",
            level9profitcustomer: "0",
            level9profitmember: "0",
            level9commissions: "0",

            level10membership: "0",
            level10profitcustomer: "0",
            level10profitmember: "0",
            level10commissions: "0",

            bonus:""

        };
        this.pathname = "rank";
        this.engine = new RequestEngine();

        this.idFileBase64 = "";
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            this.setState({
                ...edit,
            });
        }
        this.prepareData();
    }

    onChangePic(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64 = result;
        });
    }

    async prepareData() {
        let responseuni = await this.engine.getItemlistDirect("rank");
        if (responseuni) {
            const data = responseuni.data.data;
            let ranklist = [];
            ranklist.push({ value: "", label: "none" });
            data.map((p) => {
                ranklist.push({ value: p._id, label: p.title });
            });
            this.setState({
                ranklist,
            });
        }
    };
    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })

        let data = { ...this.state }
        data.picture=this.idFileBase64

        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            this.props.history.push('/admin/' + this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const { loading, isLoading,  title, orderx,customerpercent,leg1rank,leg2rank,legcrank,
            memberpercent,ranklist,
            duration,description,rank,
            droppercent,
            leg1,leg2,legc,

            dc,
            level1membership, level1profitcustomer, level1profitmember, level1commissions,
            level2membership, level2profitcustomer, level2profitmember, level2commissions,
            level3membership, level3profitcustomer, level3profitmember, level3commissions,
            level4membership, level4profitcustomer, level4profitmember, level4commissions,
            level5membership, level5profitcustomer, level5profitmember, level5commissions,
            level6membership, level6profitcustomer, level6profitmember, level6commissions,
            level7membership, level7profitcustomer, level7profitmember, level7commissions,
            level8membership, level8profitcustomer, level8profitmember, level8commissions,
            level9membership, level9profitcustomer, level9profitmember, level9commissions,
            level10membership, level10profitcustomer, level10profitmember, level10commissions,


            bonus,

            credit} = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <FormRow label={"title"} name="title" data={title} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"dc"} name="dc" data={dc} changeInput={this.changeInput.bind(this)} />
                                        <Row>
                                            <Label sm="2">{strings.picture}  </Label>
                                            <Col sm="5">
                                                <FormGroup >
                                                    <ImageUpload  placeholder={this.state.fullpicture} labelupload="Select Image" onChange={(e) => this.onChangePic(e)}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormRow label={"Leg 1"} type={"number"} name="leg1" data={leg1} changeInput={this.changeInput.bind(this)} />
                                        <SelectRow
                                            label={"Leg 1 needed rank"}
                                            name="leg1rank"
                                            defaultValue={leg1rank}
                                            data={ranklist}
                                            changeInput={this.changeInput.bind(this)}
                                        />

                                        <FormRow label={"Leg 2"} type={"number"} name="leg2" data={leg2} changeInput={this.changeInput.bind(this)} />
                                        <SelectRow
                                            label={"Leg 2 needed rank"}
                                            name="leg2rank"
                                            defaultValue={leg2rank}
                                            data={ranklist}
                                            changeInput={this.changeInput.bind(this)}
                                        />

                                        <FormRow label={"Leg C"} type={"number"} name="legc" data={legc} changeInput={this.changeInput.bind(this)} />
                                        <SelectRow
                                            label={"Leg C needed rank"}
                                            name="legcrank"
                                            defaultValue={legcrank}
                                            data={ranklist}
                                            changeInput={this.changeInput.bind(this)}
                                        />

                                        <FormRow label={"Total credit"} name="credit" data={credit}  type={"number"} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label={"rank order"} name="orderx" data={orderx}  type={"number"} changeInput={this.changeInput.bind(this)} />



                                        <FormRow label={"description"} required={false} name="description" data={description}  type={"textarea"} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label={"bonus"} required={false} name="bonus" data={bonus}  type={"textarea"} changeInput={this.changeInput.bind(this)} />

                                    </CardBody>

                                    <CardHeader>
                                        <CardTitle tag="h4">Memeber/Customer Percent</CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        {/* Level 1 */}
                                        <FormRow label={"Level 1 - ON MEMBERSHIP"} type={"number"} name="level1membership" data={level1membership} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 1 - PROFIT CUSTOMER"} type={"number"} name="level1profitcustomer" data={level1profitcustomer} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 1 - PROFIT MEMBER"} type={"number"} name="level1profitmember" data={level1profitmember} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 1 - ON COMMISSIONS"} type={"number"} name="level1commissions" data={level1commissions} changeInput={this.changeInput.bind(this)} />

                                        {/* Level 2 */}
                                        <FormRow label={"Level 2 - ON MEMBERSHIP"} type={"number"} name="level2membership" data={level2membership} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 2 - PROFIT CUSTOMER"} type={"number"} name="level2profitcustomer" data={level2profitcustomer} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 2 - PROFIT MEMBER"} type={"number"} name="level2profitmember" data={level2profitmember} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 2 - ON COMMISSIONS"} type={"number"} name="level2commissions" data={level2commissions} changeInput={this.changeInput.bind(this)} />

                                        {/* Level 3 */}
                                        <FormRow label={"Level 3 - ON MEMBERSHIP"} type={"number"} name="level3membership" data={level3membership} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 3 - PROFIT CUSTOMER"} type={"number"} name="level3profitcustomer" data={level3profitcustomer} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 3 - PROFIT MEMBER"} type={"number"} name="level3profitmember" data={level3profitmember} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 3 - ON COMMISSIONS"} type={"number"} name="level3commissions" data={level3commissions} changeInput={this.changeInput.bind(this)} />

                                        {/* Level 4 */}
                                        <FormRow label={"Level 4 - ON MEMBERSHIP"} type={"number"} name="level4membership" data={level4membership} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 4 - PROFIT CUSTOMER"} type={"number"} name="level4profitcustomer" data={level4profitcustomer} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 4 - PROFIT MEMBER"} type={"number"} name="level4profitmember" data={level4profitmember} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 4 - ON COMMISSIONS"} type={"number"} name="level4commissions" data={level4commissions} changeInput={this.changeInput.bind(this)} />

                                        {/* Level 5 */}
                                        <FormRow label={"Level 5 - ON MEMBERSHIP"} type={"number"} name="level5membership" data={level5membership} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 5 - PROFIT CUSTOMER"} type={"number"} name="level5profitcustomer" data={level5profitcustomer} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 5 - PROFIT MEMBER"} type={"number"} name="level5profitmember" data={level5profitmember} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 5 - ON COMMISSIONS"} type={"number"} name="level5commissions" data={level5commissions} changeInput={this.changeInput.bind(this)} />

                                        {/* Level 6 */}
                                        <FormRow label={"Level 6 - ON MEMBERSHIP"} type={"number"} name="level6membership" data={level6membership} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 6 - PROFIT CUSTOMER"} type={"number"} name="level6profitcustomer" data={level6profitcustomer} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 6 - PROFIT MEMBER"} type={"number"} name="level6profitmember" data={level6profitmember} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 6 - ON COMMISSIONS"} type={"number"} name="level6commissions" data={level6commissions} changeInput={this.changeInput.bind(this)} />

                                        {/* Level 7 */}
                                        <FormRow label={"Level 7 - ON MEMBERSHIP"} type={"number"} name="level7membership" data={level7membership} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 7 - PROFIT CUSTOMER"} type={"number"} name="level7profitcustomer" data={level7profitcustomer} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 7 - PROFIT MEMBER"} type={"number"} name="level7profitmember" data={level7profitmember} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 7 - ON COMMISSIONS"} type={"number"} name="level7commissions" data={level7commissions} changeInput={this.changeInput.bind(this)} />

                                        {/* Level 8 */}
                                        <FormRow label={"Level 8 - ON MEMBERSHIP"} type={"number"} name="level8membership" data={level8membership} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 8 - PROFIT CUSTOMER"} type={"number"} name="level8profitcustomer" data={level8profitcustomer} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 8 - PROFIT MEMBER"} type={"number"} name="level8profitmember" data={level8profitmember} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 8 - ON COMMISSIONS"} type={"number"} name="level8commissions" data={level8commissions} changeInput={this.changeInput.bind(this)} />

                                        {/* Level 9 */}
                                        <FormRow label={"Level 9 - ON MEMBERSHIP"} type={"number"} name="level9membership" data={level9membership} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 9 - PROFIT CUSTOMER"} type={"number"} name="level9profitcustomer" data={level9profitcustomer} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 9 - PROFIT MEMBER"} type={"number"} name="level9profitmember" data={level9profitmember} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 9 - ON COMMISSIONS"} type={"number"} name="level9commissions" data={level9commissions} changeInput={this.changeInput.bind(this)} />

                                        {/* Level 10 */}
                                        <FormRow label={"Level 10 - ON MEMBERSHIP"} type={"number"} name="level10membership" data={level10membership} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 10 - PROFIT CUSTOMER"} type={"number"} name="level10profitcustomer" data={level10profitcustomer} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 10 - PROFIT MEMBER"} type={"number"} name="level10profitmember" data={level10profitmember} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"Level 10 - ON COMMISSIONS"} type={"number"} name="level10commissions" data={level10commissions} changeInput={this.changeInput.bind(this)} />
                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


