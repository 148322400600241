const lang = {
    msgsaved:"Data Saved",
    email:"Email",
    studentid:"ناسنامەی خوێندکار ",
    actions :"ڕێکارەکان",
    export:"هەناردە",
    picture:"وێنە",
    title:"ناونیشانەکە",
    class:"پۆل",
    created:"به‌رواری درووستكردن",
    previous:"پێشووتر",
    next:"دواتر",
    edit:"پێداچوونەوە",
    delete:"سڕینەوە",
    page:"لاپەڕە",
    loading:"جار التحميل",
    order:"ئامادەکاری",
    event:"پێشهات",
    date:"بەروارەکە",
    day:"ڕۆژ",
    teachermatier:"مادەی مامۆستا",
    paid:"پارەی دا",
    amount:"بڕ",
    invoicedate:"ڕۆژی پارە دان",
    invoicenumber:"ژمارەی فاکتورە",
    profilepicture:"وێنە",
    profile:"وێنە",
    father:"الآب",
    phone:"ته‌له‌فۆنه‌که‌",
    name:"ناو",
    mother:"دایک",
    teacher:"مامۆستا",
    matier:"بابەت",
    username:"ناوی بەکارهێنەر ",
    type:"جۆر",
    healthtype:"جۆری تەندروستی ",
    note:"ملحوظة",
    fatherphone:"تەلەفۆنی باوک",
    motherphone:"تەلەفۆنی دایک",
    pendingpayment:"الدفعة المنتظرة",
    amounttype:"جۆری بڕە پارە",
    totalstudent:"کۆی گشتی خوێندکاران",
    year:"ساڵ",
    successstudentcount:"ژمارەی خوێندکارە سەرکەوتووەکان",
    faildstudentcount:"ژمارەی خوێندکارە شکستخواردووەکان",
    presentcounter:"عداد الحاضر",
    absentcounter:"ژمێرەری ئامادەنەبوون",
    logmessage:"پەیامی لۆگ",
    response:"وەڵامدانەوە",
    text:"دەق",
    score:"ئەنجامێکە",
    information:"زانیاری",
    save:"پاراستن",
    select:"دەسنیاشنکردن",
    pdf:"pdf",
    address:"ناونیشانەکە",
    fromtime:"کاتم هەیە",
    totime:"بۆ کات",
    accounttype:"جۆری ئەژمار",
    paymenttype:"جۆری پاره‌دان",
    paymentamounttype:"نوع مبلغ الدفع",
    time:"كات",
    csv:"csv",
    message:"نامەكە",
    fullname:"اناوى تەواو",
    stringsmotherfamiliy:"خێزانی دایکی",
    password:"نهێنوشە",
    registred:"مسجل فىالدم",
    blood:"خوێن",
    dob:"بەروارى لەدایکبوون",
    registration:"تۆمارکردن",
    nationality:"ڕەگەزنامە",
    sex:"سێکس",
    firstname:"ناوی یەکەم",
    lastname:"ناوی خێزان",


    report: "ڕاپۆرت",
    creport:"ڕاپۆرتی چارتس",
    classreport:"ڕاپۆرتی پۆل",
    heareport:"ڕاپۆرتی تەندروستی",
    preport:"ڕاپۆرتی دایک و باوک",
    payrol:"ڕاپۆرتی مووچە",
    rereport:"ڕاپۆرتی ئەنجامەکان",
    studere:"ڕاپۆرتی خوێندکار",
    attreport:"ڕاپۆرتی ئامادەبوون",
    log: "لۆگی بەکارهێنەر",
    add: "زیادکردن",
    home: "ماڵەوە",
    lang:"کوردی",
    news:"هەواڵەکان",
    managergmgnet:"کارگێڕی",
    banner:"بانەر",
    book:"کتێبخانە",
    uni:"زانکۆ",
    cal:"ساڵنامە",
    department:"بەش",
    section:"بەش",
    certificate:"بڕوانامە",
    studenclpr:"پێشکەوتنی پۆلی خوێندکار",
    schedule:"خشتە",
    account:"هەژمار",
    family:"خێزان",
    student:"خوێندکاران",
    parent:"دایک",
    teachers:"پرۆفیسۆرەکان",
    matx:"بابەتەکان",
    teamat:"پرۆفیسۆرەکان بابەتەکان",
    payroll:"المدفوعات",
    fees:"مووچە",
    feestype:"جۆری کرێ",
    health:"تەندروستی",
    healtype:"جۆری تەندروستی",
    classx:"پۆلەکان",
    agenda:"کارنامە",
    attx:"ئامادەبوون",
    exam:"تاقیکردنەوەکان",
    gradsx:"نمرەکان",
    uploadx:"بەرزکردنەوەی فایل",
    quiz:"کویز",
    quizres:"وەڵامی کویز",
    notx:"ئاگادارکردنەوەکان",
    maangx:"بەڕێوەبەر",
    managtype:"جۆری بەڕێوەبەر",
    quizupload:"کویزێکی دابەزاندن",

}

export default lang;
