import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import { Utilites } from "../../core/Utilites";
import Constants from "../../core/Constants";


export default class NotificationAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",
            message: "",
            user: [],
            userlist: [],
            loading: false,
        };
        this.pathname = "notification";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            this.setState({
                ...edit,
            });
        }
        this.prepareData();

    }
    async prepareData() {
        let responseuni = await this.engine.getItemlistDirect("user");
        if (responseuni) {
            const data = responseuni.data.data;
            let userlist = [];
            data.map((p) => {
                userlist.push({ value: p._id, label: p.first_name+" "+p.last_name });
            });
            this.setState({
                userlist,
            });
        }
    };



    handleValidSubmit = async () => {
        this.setState({
            loading: true
        })

        const { _id, message,user } = this.state;


        let userid = []
        user.map(i=>{
            if(i.value){
                userid.push(i.value)
            }else{
                userid.push(i)
            }
        })
        const data = {
            userid,
            message,
            _id
        };

        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            this.props.history.push('/admin/' + this.pathname);
        }
        this.setState({
            loading: false
        })
    };



    render() {
        // taking all the states
        const { loading, isLoading, message,user,userlist } = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">
                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>


                                        <SelectRow label="user" isMulti={true}  name="user"   data={userlist} defaultValue={user && user.map((item) => {
                                            return userlist.find(v => v.value === item.value || v.value === item)
                                        })} changeInput={data =>
                                            this.setState({ user: data })
                                        }  />
                                        <FormRow label={"message"} type={"textarea"} name="message" data={message} changeInput={this.changeInput.bind(this)} />
                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


