import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import SelectRow from "../../components/Select/SelectRow";


export default class PlanAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",
            title: "",
            oldPrice:"",
            deadline:"30",
            featured:"no",
            description:"",
            price:"",
        };
        this.pathname = "plan";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            debugger
            this.setState({
                ...edit,
            });
        }
    }
   handleValidSubmit = async () => {

        this.setState({
            loading: true
        })

        let data = { ...this.state }

        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            this.props.history.push('/admin/' + this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const { loading, isLoading,  title,deadline,
            description,oldPrice,featured,
            price} = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <FormRow label={"title"} name="title" data={title} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"description"} name="description" type={"textarea"} data={description} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label={"old price"} name="oldPrice" data={oldPrice}  type={"number"} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label={"price"} name="price" data={price}  type={"number"} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label={"deadline - days"} name="deadline" data={deadline}  type={"number"} changeInput={this.changeInput.bind(this)} />
                                        <SelectRow
                                            label={"Recommended"}
                                            name="featured"
                                            defaultValue={featured}
                                            data={this.createSelectValue(["yes","no"])}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


