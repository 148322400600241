import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";


export default class PaymentAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id:"",
            type:"cash",
            projectphase:"",
            amount:"0",
            projectphaselist:[],
            loading : false,
        };
        this.pathname = "payment";
        this.engine = new RequestEngine();
        this.prepareData()
    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("projectphase");
        if(responseuni){
            const data = responseuni.data.data;
            let projectphaselist = []
            data.map(p =>{
                projectphaselist.push( { value: p._id, label: p.project?.name+" / client : "+p.project?.client?.name})
                }
            )
            this.setState({
                projectphaselist
            });
        }


    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit){
            debugger
            this.setState({
                ...edit,
                projectphase:edit.projectphase?.id
            });
        }
    }


    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })

        let data  = {...this.state}

        const response = await this.engine.saveItem(this.pathname,data)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const {loading,isLoading,type,projectphaselist,projectphase,amount }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <SelectRow label={"project phase"}  name="projectphase"  defaultValue={projectphase}  data={projectphaselist} changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label={"type"}  name="type"  defaultValue={type}  data={this.createSelectValue(["CASH","OMT","WISH"])} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"amount"}  name="amount" data={amount} type={"number"} changeInput={this.changeInput.bind(this)}  />

                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


