import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Label,
    Row,
    Col
} from "reactstrap";

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import { AvForm } from 'availity-reactstrap-validation';

export default class ContentAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        const html = '';
        const contentBlock = htmlToDraft(html);

        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.state = {
            isLoading: false,
            title:"",
            description:"",
            _id:"",
            key:"",
            loading : false,
            editorState : editorState,
        };



        this.pathname = "content";
        this.engine = new RequestEngine();
    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    componentDidMount() {


        const edit = this.props.location.data


        if (edit){

            const html = edit.description;
            const contentBlock = htmlToDraft(html);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
                ...edit,
                editorState:editorState,
            });
        }

    }


    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })


        const {  _id,title,editorState,key }= this.state;


        const description = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        const data= {
            title,
            _id,
            description,
            key
        }

        try{
            let  response = {}

                response= await this.engine.saveItem(this.pathname,data)

            if(response && (response.status === 200 || response.status === 201)){
                this.props.history.push('/admin/' + this.pathname);
            }
        }catch (e) {
            console.log(e)
            alert("Error Please try again")
        }
        this.setState({
            loading: false
        })

    };

    onChangePic(e) {
        this.setState({picture:e.target.files[0]})
    }
    render() {
        // taking all the states
        const {loading,isLoading, editorState,  title,key }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="Title" name="title" data={title}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="key"  name="key" data={key}   changeInput={this.changeInput.bind(this)} />
                                        <Row>
                                            <Label sm="2">Description</Label>
                                            <Col sm="7" >
                                                <FormGroup   >
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={this.onEditorStateChange}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col className="label-on-right" tag="label" sm="3">
                                                <code>required</code>
                                            </Col>
                                        </Row>


                                    </CardBody>



                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


